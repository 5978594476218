// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = [ "button" ]
  static values = { invite: String }

  connect() {
    console.log('Report#connect')
  }

  sendInvite(event) {

    let that = this

    this.buttonTarget.innerHTML = '<i class="fal fa-redo fa-spin"></i> Sending'

    setTimeout(
        function()
        {
          Rails.ajax({
            type: 'GET',
            url: that.inviteValue,
            dataType: 'json',
            success: function (response) {
              that.buttonTarget.innerHTML = 'Sent'
            },
            error: function (response) {
              console.log('Setting could not be saved.')
            }
          })
        }, 2000);


  }
}
