// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"
import Rails from '@rails/ujs'
import Chart from 'chart.js/auto'

export default class extends Controller {
  static targets = [ "output" ]
  static values = { store: String, labels: String, results: String, chartIdent: String }

  connect() {
    this.drawRadar()
  }

  drawRadar() {
    let that = this

    var ctx = document.getElementById(this.chartIdentValue).getContext('2d');
    setTimeout(function(){
      var myRadarChart = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: JSON.parse(that.labelsValue),
          datasets: [{
            data: JSON.parse(that.resultsValue),
            backgroundColor: [
              "#fcb868",
              "rgba(255, 205, 86, 0.8)",
              "rgba(75, 192, 192, 0.8)",
              "rgba(54, 162, 235, 0.8)",
              "rgba(153, 102, 255, 0.8)",
              "rgba(255, 99, 132, 0.8)",
              "rgba(255, 205, 86, 0.8)",
              "rgba(75, 192, 192, 0.8)",
              "rgba(54, 162, 235, 0.8)",
              "rgba(153, 102, 255, 0.8)",
              "rgba(255, 205, 86, 0.8)",
              "rgba(75, 192, 192, 0.8)",
              "rgba(54, 162, 235, 0.8)",
              "rgba(153, 102, 255, 0.8)",
              "rgba(255, 99, 132, 0.8)",
              "rgba(255, 205, 86, 0.8)",
              "rgba(75, 192, 192, 0.8)",
              "rgba(54, 162, 235, 0.8)",
              "rgba(153, 102, 255, 0.8)",
              "rgba(201, 203, 207, 0.2)"],
            borderColor: [
              "#ab6615",
              "rgba(255, 205, 86, 0.8)",
              "rgba(75, 192, 192, 0.8)",
              "rgba(54, 162, 235, 0.8)",
              "rgba(153, 102, 255, 0.8)",
              "rgba(255, 99, 132, 0.8)",
              "rgba(255, 205, 86, 0.8)",
              "rgba(75, 192, 192, 0.8)",
              "rgba(54, 162, 235, 0.8)",
              "rgba(153, 102, 255, 0.8)",
              "rgba(255, 205, 86, 0.8)",
              "rgba(75, 192, 192, 0.8)",
              "rgba(54, 162, 235, 0.8)",
              "rgba(153, 102, 255, 0.8)",
              "rgba(255, 99, 132, 0.8)",
              "rgba(255, 205, 86, 0.8)",
              "rgba(75, 192, 192, 0.8)",
              "rgba(54, 162, 235, 0.8)",
              "rgba(153, 102, 255, 0.8)",
              "rgb(201, 203, 207)"],

            borderWidth: 1,
            pointRadius: 10
          }]
        },
        options: {
          indexAxis: 'y',
          tension: 0.4,
          plugins: {
            legend: {
              display: false,
            },
          },
          tooltips: {
            callbacks: {
              label: function(tooltipItem) {
                return tooltipItem.yLabel;
              }
            }
          },
          color: [
            'red'
          ],
          scales: {
            r: {
              grid: {
                circular: true,
              },
            },
          },
          scale: {
            r: {
              grid: {
                circular: true,
              },
              suggestedMin: 0,
              suggestedMax: 100
            },
            ticks: {
              beginAtZero: true,
              suggestedMax: 100,
              stepSize: 20
            },
            pointLabels: {
              fontSize: 18
            }
          }
        }
      });

    }, 500);

    setTimeout(function(){
      console.log(`>>>> ${document.getElementById('myChart').toDataURL()}`)
      console.log(`>>>> ${that.storeValue}`)

      let data = new FormData()
      data.append('image_data', document.getElementById('myChart').toDataURL())

      Rails.ajax({
        type: 'PATCH',
        url: that.storeValue,
        dataType: 'json',
        data: data,
        success: function (response) {
          console.log('Image Saved.')
        },
        error: function (response) {
          console.log('Setting could not be saved.')
        }
      })
    }, 2000);

  }
}
