// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = [ "self" ]
  static values = { invoicedUrl: String, freeUrl: String}

  connect() {
    console.log('AdminReport#connect')
  }

  invoiced() {
    console.log(this.invoicedUrlValue)
    let that = this

    Rails.ajax({
      type: 'GET',
      url: this.invoicedUrlValue,
      dataType: 'json',
      success: function (response) {
        that.selfTarget.outerHTML = response.html
      },
      error: function (response) {
        console.log('Setting could not be saved.')
      }
    })
  }

  free() {
    console.log(this.freeUrlValue)
    let that = this

    Rails.ajax({
      type: 'GET',
      url: this.freeUrlValue,
      dataType: 'json',
      success: function (response) {
        that.selfTarget.outerHTML = response.html
      },
      error: function (response) {
        console.log('Setting could not be saved.')
      }
    })
  }

}
