// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = [ "edit" ]
  static values = { mapping_url: String }

  connect() {
    console.log('QuestionTable#connect')

  }

  editMapping(event) {
    let edit_path = event.target.name;
    console.log(`QuestionTable#editMapping: name: ${edit_path}`)

    let that = this
    Rails.ajax({
      type: 'GET',
      url: edit_path,
      dataType: 'json',
      success: function (response) {
        that.editTarget.innerHTML = response.html
      },
      error: function (response) {
        console.log('filter failed')
      }
    })

  }


}
