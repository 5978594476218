import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"
import { Application } from "@hotwired/stimulus"
const application = Application.start()

import * as ActiveStorage from "@rails/activestorage"
import * as bootstrap from "bootstrap/dist/js/bootstrap.bundle"
window.bootstrap = bootstrap

Rails.start()
ActiveStorage.start()

require("trix")
require("@rails/actiontext")

import "./controllers"
import $ from 'jquery'

// Configure Stimulus development experience
application.warnings = true
application.debug = false
window.Stimulus = application
